import { BaseEndpoint } from "./Base"

export default class CertificatePreviewEndpoint extends BaseEndpoint {
  static get selector() {
    return "certificates.previews"
  }

  create({ certificateId, params = {}, options = {} } = {}) {
    this.verifyData("create", { certificateId })

    return this.action({
      method:  "post",
      url:     `/certificates/${certificateId}/preview`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
