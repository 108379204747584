import { BaseEndpoint } from "./Base"

export default class SearchCategoryEndpoint extends BaseEndpoint {
  static get selector() {
    return "searches.categories"
  }

  index({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/search/categories",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
