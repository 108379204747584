import { BaseEndpoint } from "./Base"

export default class CertificateEndpoint extends BaseEndpoint {
  static get selector() {
    return "certificates"
  }

  index({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/certificates",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ certificateId, params = {}, options = {} } = {}) {
    this.verifyData("show", { certificateId })

    return this.action({
      method:  "get",
      url:     `/certificates/${certificateId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/certificates",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ certificateId, params = {}, options = {} } = {}) {
    this.verifyData("update", { certificateId })

    return this.action({
      method:  "patch",
      url:     `/certificates/${certificateId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  delete({ certificateId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { certificateId })

    return this.action({
      method:  "delete",
      url:     `/certificates/${certificateId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
