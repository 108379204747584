import { BaseEndpoint } from "./Base"

export default class SearchOwnerEndpoint extends BaseEndpoint {
  static get selector() {
    return "searches.owners"
  }

  index({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/search/owners",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
