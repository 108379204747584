import { BaseEndpoint } from "./Base"

export default class CourseEditorEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.editors"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/course_editors`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("create", { courseId })

    return this.action({
      method:  "post",
      url:     `/courses/${courseId}/course_editors/share_course`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
